@use 'variables' as v;

// Font

@font-face {
  font-family: 'Atelier';
  src: url('#{v.$atelier-font-path}/Atelier.woff2') format('woff2'),
  url('#{v.$atelier-font-path}/Atelier.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

// base class

.atelier {
  display: inline-block;
  font: normal normal normal 1.5em/1 'Atelier';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  vertical-align: middle;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0.4em;
}

//  change icon size

.atelier-sm {
  font-size: 1em;
  padding-right: 0;
}

.atelier-lg {
  font-size: 2.4em;
  padding-right: 0.5em;
}

@media screen and (max-width: 576px) {
  .atelier-lg {
    font-size: 4vw;
  }
}

.atelier-np {
  padding-right: 0;
}

/* icons */
$icons: (
  'resleri-mats': 'ea02',
  'resleri-research': 'ea03',
  'resleri-expedition': 'ea04',
  'resleri-tower': 'ea05',
  'resleri-recipes': 'ea07',
  'resleri-memoria': 'ea08',
  'resleri-char': 'ea09',
  'resleri-gacha': 'ea0a',
  'resleri-alchemy': 'ea0b',
  'resleri-wind': 'ea0c',
  'resleri-slash': 'ea0d',
  'resleri-icon': 'ea0e',
  'resleri-pierce': 'ea0f',
  'resleri-lightning': 'ea10',
  'resleri-impact': 'ea11',
  'resleri-ice': 'ea12',
  'resleri-fire': 'ea13',
  'ryza3-race-king-philuscha': 'ea14',
  'ryza3-category-key-items': 'ea15',
  'ryza3-race-code-of-the-universe': 'ea16',
  'ryza3-race-chameleon': 'ea17',
  'ryza3-secret-keys': 'ea18',
  'ryza3-race-small-devil': 'ea19',
  'ryza3-race-sloth': 'ea1a',
  'ryza3-race-skeleton': 'ea1b',
  'ryza3-race-raptor': 'ea1c',
  'ryza3-race-mechanic': 'ea1d',
  'ryza3-race-golem': 'ea1e',
  'ryza3-race-fenrir': 'ea1f',
  'ryza3-race-elemental': 'ea20',
  'ryza3-race-elder-treant': 'ea21',
  'ryza3-race-dryad': 'ea22',
  'ryza3-category-core-ingredients': 'ea23',
  'firis-race-demon': 'ea24',
  'firis-attack': 'ea25',
  'firis-weapon': 'ea26',
  'firis-armor': 'ea27',
  'firis-synthesis': 'ea28',
  'firis-heal': 'ea29',
  'firis-accessory': 'ea2a',
  'firis-race-spirit': 'ea2b',
  'firis-category-clay': 'ea2c',
  'firis-resist-ailment': 'ea2d',
  'firis-resist-slash': 'ea2e',
  'firis-resist-pierce': 'ea2f',
  'firis-resist-magic': 'ea30',
  'firis-resist-lightning': 'ea31',
  'firis-resist-impact': 'ea32',
  'firis-resist-ice': 'ea33',
  'firis-resist-fire': 'ea34',
  'firis-category-flower': 'ea35',
  'firis-race-undead': 'ea36',
  'firis-category-armor-mat': 'ea37',
  'firis-race-plant': 'ea38',
  'firis-race-humanoid': 'ea39',
  'firis-race-beast': 'ea3a',
  'firis-race-animal': 'ea3b',
  'firis-race-alchemic': 'ea3c',
  'firis-category-weapon-mat': 'ea3d',
  'firis-category-weapon': 'ea3e',
  'firis-category-sweets': 'ea3f',
  'firis-category-poison-mat': 'ea40',
  'firis-category-puniball': 'ea41',
  'firis-category-sand': 'ea42',
  'firis-category-plant': 'ea43',
  'firis-category-paper': 'ea44',
  'firis-category-object': 'ea45',
  'firis-category-neutralizer': 'ea46',
  'firis-category-gunpowder': 'ea47',
  'firis-category-foodstuff': 'ea48',
  'firis-category-animal-mat': 'ea49',
  'firis-category-accessory': 'ea4a',
  'sophie2-usable': 'ea4b',
  'sophie2-race-erde-wiege': 'ea4c',
  'sophie2-race-dragon': 'ea4d',
  'sophie2-race-dragonaire': 'ea4e',
  'sophie2-race-medium-groll': 'ea4f',
  'sophie2-race-beast': 'ea50',
  'sophie2-race-ghost': 'ea51',
  'sophie2-race-elvira': 'ea52',
  'sophie2-race-dryad': 'ea53',
  'sophie2-race-dream-eater': 'ea54',
  'sophie2-category-catalysts': 'ea55',
  'sophie2-race-bird': 'ea56',
  'sophie2-element-wind': 'ea57',
  'sophie2-race-bat': 'ea58',
  'sophie2-race-apparition': 'ea59',
  'sophie2-race-apostle': 'ea5a',
  'sophie2-race-magical-creature': 'ea5b',
  'sophie2-element-magic': 'ea5c',
  'sophie2-element-lightning': 'ea5d',
  'sophie2-element-light': 'ea5e',
  'sophie2-element-ice': 'ea5f',
  'sophie2-element-fire': 'ea60',
  'sophie2-category-talismans': 'ea61',
  'sophie2-category-spices': 'ea62',
  'sophie2-category-seafood': 'ea63',
  'sophie2-race-jellyfish': 'ea64',
  'sophie2-type-exploration': 'ea65',
  'sophie2-tool-slingshot': 'ea66',
  'sophie2-tool-pickaxe': 'ea67',
  'sophie2-tool-hammer': 'ea68',
  'sophie2-race-small-groll': 'ea69',
  'sophie2-race-sea-serpent': 'ea6a',
  'sophie2-race-rabbit': 'ea6b',
  'sophie2-race-puni': 'ea6c',
  'sophie2-race-plant': 'ea6d',
  'sophie2-race-mushroom': 'ea6e',
  'sophie2-race-golem': 'ea6f',
  'escha-accessory': 'ea70',
  'shallie-water': 'ea71',
  'shallie-wind': 'ea72',
  'escha-weapon': 'ea73',
  'escha-bomb': 'ea74',
  'escha-heal': 'ea75',
  'escha-buff': 'ea76',
  'escha-armor': 'ea77',
  'shallie-fire': 'ea78',
  'shallie-earth': 'ea79',
  'shallie-race-alchemic': 'ea7a',
  'shallie-category-food': 'ea7b',
  'shallie-category-ancient-power': 'ea7c',
  'shallie-race-slag': 'ea7d',
  'shallie-race-rare-slag': 'ea7e',
  'shallie-race-humanoid': 'ea7f',
  'shallie-race-elemental': 'ea80',
  'shallie-race-dragon': 'ea81',
  'shallie-race-beast': 'ea82',
  'shallie-race-animal': 'ea83',
  'shallie-category-sand': 'ea84',
  'shallie-category-wood': 'ea85',
  'shallie-category-w-bell': 'ea86',
  'shallie-category-vegetable': 'ea87',
  'shallie-category-thread': 'ea88',
  'shallie-category-sundry': 'ea89',
  'shallie-category-snack': 'ea8a',
  'shallie-category-slag-part': 'ea8b',
  'shallie-category-seasoning': 'ea8c',
  'shallie-category-poison': 'ea8d',
  'shallie-category-plant': 'ea8e',
  'shallie-category-pelt': 'ea8f',
  'shallie-category-paper': 'ea90',
  'shallie-category-ore': 'ea91',
  'shallie-category-a-light-armor': 'ea92',
  'shallie-category-natural': 'ea93',
  'shallie-category-mystery': 'ea94',
  'shallie-category-metal': 'ea95',
  'shallie-category-medicine': 'ea96',
  'shallie-category-meat': 'ea97',
  'shallie-category-magic-tool': 'ea98',
  'shallie-category-magic-herb': 'ea99',
  'shallie-category-lumber': 'ea9a',
  'shallie-category-liquid': 'ea9b',
  'shallie-category-key-item': 'ea9c',
  'shallie-category-jewel': 'ea9d',
  'shallie-category-ingot': 'ea9e',
  'shallie-category-gunpowder': 'ea9f',
  'shallie-category-grain': 'eaa0',
  'shallie-category-gas': 'eaa1',
  'shallie-category-fuel': 'eaa2',
  'shallie-category-fruit': 'eaa3',
  'shallie-category-adventure': 'eaa4',
  'shallie-category-fish': 'eaa5',
  'shallie-category-feather': 'eaa6',
  'shallie-category-elixir': 'eaa7',
  'shallie-category-dragon-material': 'eaa8',
  'shallie-category-cloth': 'eaa9',
  'shallie-category-clay': 'eaaa',
  'shallie-category-book': 'eaab',
  'shallie-category-bomb': 'eaac',
  'shallie-category-bee-hive': 'eaad',
  'shallie-category-aroma': 'eaae',
  'shallie-category-apple': 'eaaf',
  'shallie-category-antique': 'eab0',
  'shallie-category-animal': 'eab1',
  'shallie-category-ancient-relic': 'eab2',
  'shallie-category-accessory': 'eab3',
  'escha-category-liquid': 'eab4',
  'totori-category-supplement': 'eab5',
  'meruru-category-housing': 'eab6',
  'totori-category-seasoning': 'eab7',
  'totori-category-key-item': 'eab8',
  'totori-category-cloth': 'eab9',
  'meruru-category-tourism': 'eaba',
  'meruru-category-security': 'eabb',
  'meruru-category-farming': 'eabc',
  'meruru-category-development': 'eabd',
  'meruru-category-construction': 'eabe',
  'meruru-category-mushroom': 'eabf',
  'totori-category-accessory': 'eac0',
  'totori-category-pie': 'eac1',
  'totori-category-dessert': 'eac2',
  'totori-category-cooking': 'eac3',
  'totori-category-sundry': 'eac4',
  'totori-category-magic-tool': 'eac5',
  'totori-category-elixir': 'eac6',
  'totori-category-ingot': 'eac7',
  'totori-category-medicine': 'eac8',
  'totori-category-bomb': 'eac9',
  'totori-category-threads': 'eaca',
  'totori-category-spice': 'eacb',
  'totori-category-fuel': 'eacc',
  'totori-category-vegetable': 'eacd',
  'totori-category-meat': 'eace',
  'totori-category-lumber': 'eacf',
  'totori-category-healing': 'ead0',
  'totori-category-poison': 'ead1',
  'totori-category-mystery': 'ead2',
  'totori-category-gunpowder': 'ead3',
  'totori-category-liquid': 'ead4',
  'totori-category-plant': 'ead5',
  'totori-category-ore': 'ead6',
  'totori-category-jewel': 'ead7',
  'totori-category-fish': 'ead8',
  'totori-category-bug': 'ead9',
  'ryza2-book': 'eada',
  'ryza2-material': 'eadb',
  'ryza2-equipment': 'eadc',
  'ryza2-shop': 'eadd',
  'ryza2-category-bombs': 'eade',
  'ryza2-category-accessories': 'eadf',
  'ryza2-category-weapons': 'eae0',
  'ryza2-tool-hammer': 'eae1',
  'ryza2-medium-enemy': 'eae2',
  'ryza2-category-gunpowder': 'eae3',
  'ryza2-type-attack': 'eae4',
  'ryza2-category-seafood': 'eae5',
  'ryza2-category-cloth': 'eae6',
  'ryza2-category-magic-tools': 'eae7',
  'ryza2-category-spices': 'eae8',
  'ryza2-category-medicine': 'eae9',
  'ryza2-category-food': 'eaea',
  'ryza2-category-seeds': 'eaeb',
  'ryza2-category-metal': 'eaec',
  'ryza2-category-fruit': 'eaed',
  'ryza2-category-general-goods': 'eaee',
  'ryza2-category-neutralizers': 'eaef',
  'ryza2-category-magical': 'eaf0',
  'ryza2-category-dragon-mat': 'eaf1',
  'ryza2-category-animal-products': 'eaf2',
  'ryza2-category-ingots': 'eaf3',
  'ryza2-category-jewels': 'eaf4',
  'ryza2-category-gases': 'eaf5',
  'ryza2-category-lumber': 'eaf6',
  'ryza2-category-threads': 'eaf7',
  'ryza2-category-bugs': 'eaf8',
  'ryza2-category-mushrooms': 'eaf9',
  'ryza2-category-puniballs': 'eafa',
  'ryza2-category-edibles': 'eafb',
  'ryza2-category-fuel': 'eafc',
  'ryza2-category-poisons': 'eafd',
  'ryza2-category-gemstones': 'eafe',
  'ryza2-category-ore': 'eaff',
  'ryza2-category-beehives': 'eb00',
  'ryza2-category-sand': 'eb01',
  'ryza2-category-elixirs': 'eb02',
  'ryza2-category-medicinal': 'eb03',
  'ryza2-category-flowers': 'eb04',
  'ryza2-category-uni': 'eb05',
  'ryza2-category-stone': 'eb06',
  'ryza2-category-plants': 'eb07',
  'ryza2-category-essence': 'eb08',
  'ryza2-category-key-items': 'eb09',
  'ryza2-large-enemy': 'eb0a',
  'ryza2-category-water': 'eb0b',
  'ryza2-type-armor': 'eb0c',
  'ryza2-type-buff': 'eb0d',
  'ryza2-type-debuff': 'eb0e',
  'ryza2-type-weapon': 'eb0f',
  'ryza2-type-heal': 'eb10',
  'ryza2-race-guardian': 'eb11',
  'ryza2-type-synthesis': 'eb12',
  'ryza2-race-demon-beast': 'eb13',
  'ryza2-race-hedgehog': 'eb14',
  'ryza2-race-weasel-king': 'eb15',
  'ryza2-boss-enemy': 'eb16',
  'ryza2-race-roadrunner': 'eb17',
  'ryza2-race-sheep': 'eb18',
  'ryza2-race-shark': 'eb19',
  'ryza2-small-enemy': 'eb1a',
  'ryza2-race-golem': 'eb1b',
  'ryza2-race-beetle': 'eb1c',
  'ryza2-race-ancient-philuscha': 'eb1d',
  'ryza2-race-wyrm': 'eb1e',
  'ryza2-race-weasel': 'eb1f',
  'ryza2-race-scorpion': 'eb20',
  'ryza2-race-knight': 'eb21',
  'ryza2-tool-sickle': 'eb22',
  'ryza2-race-spirit': 'eb23',
  'ryza2-tool-axe': 'eb24',
  'ryza2-race-puni': 'eb25',
  'ryza2-category-tools': 'eb26',
  'ryza2-tool-bug-net': 'eb27',
  'ryza2-tool-bomb-rod': 'eb28',
  'ryza2-type-accessory': 'eb29',
  'ryza2-tool-fishing-rod': 'eb2a',
  'ryza2-tool-water': 'eb2b',
  'ryza2-category-furniture': 'eb2c',
  'ryza2-forge': 'eb2d',
  'ryza2-tool-staff': 'eb2e',
  'ryza2-tool-gather': 'eb2f',
  'ryza2-category-armor': 'eb30',
  'ryza2-wind': 'eb31',
  'ryza2-fire': 'eb32',
  'ryza2-ice': 'eb33',
  'ryza2-lightning': 'eb34',
  'ryza2-physical': 'eb35',
  'ryza2-magic': 'eb36',
  'resleri-event': 'eb37',
  'resleri-trait': 'eb38',
  'resleri-scorebattle': 'eb39',
  'resleri-cauldron': 'eb3a',
  'resleri-dungeon2': 'eb3b',
  'resleri-dungeon': 'eb3c',
  'resleri-color-l': 'eb3d',
  'resleri-color-bl': 'eb3e',
  'resleri-color-r': 'eb3f',
  'resleri-color-br': 'eb40',
);

@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

@each $name, $code in $icons {
  .atelier-#{""+$name}::before {
    content: unicode($code);
  }
}
