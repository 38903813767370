@use '../variables' as v;

// Form stuff

.mdc-text-field--outlined {
    background-color: white;
}

.mat-mdc-form-field {
    display: inline-block !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
}

.mat-icon {
    overflow: unset !important;
}

.mat-drawer-container {
    background: transparent !important;
    min-height: 100dvh;
}

.mat-sidenav-content {
    margin-left: 200px; // prevents a layout shift
}

@media screen and (max-width: 800px) {
    .mat-sidenav-content {
        margin-left: 0;
    }
  }

// Create page

.mat-chip.mat-primary {
    background-color: #20a8d8;
    color: white;
}

// Material tabs cutting off my shadows...

.tabs-overflow * {
    overflow: visible !important;
}

// the weirdest interaction on A25 collection
.cdk-dialog-container:focus {
    outline: 0;
}


// buttons

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
    background-color: v.$navbar !important;
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent:hover {
    background-color: #B8947B !important;
}

// dialog

.cdk-global-overlay-wrapper {
    overflow-y: auto;
}

.closer {
    background: rgba(0, 0, 0, 0.3);
}

.closer:before {
    position: absolute;
    content: "\274c";
    color: rgb(180, 0, 0);
    cursor: pointer;
    top: 10px;
    right: 10px;
    font-size: 1.8rem;
    background-color: white;
    height: 2.7rem;
    aspect-ratio: 1;
    text-align: center;
    border-radius: 50%;
    box-shadow: 2px 2px 2px hsl(0deg 0% 0% / 0.38);
}

.dialog-margin::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 1100px) {
    .dialog-margin {
        margin-top: 1rem;
        max-width: 50vw;
        min-width: 500px;
        overflow-y: auto; // heck webkit
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 1100px) {
    .dialog-margin {
        margin-top: 5rem;
        max-width: min(95vw, 800px);
        min-width: max(40vw, 320px);
        overflow-y: auto; // heck webkit
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        padding-bottom: 1rem;
    }
}