@use 'material';
@use 'specific/badge';
@use 'specific/breadcrumb';
@use 'specific/card';
@use 'specific/grid';
@use 'specific/navigation';
@use 'specific/material-fix';
@use "icons";
@use 'custom';
@use 'specific/table';
@use 'specific/tag';

html {
  scroll-behavior: auto !important;
}

$fa-font-path: "/media/main/font/font-awesome-6.7.2";
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
