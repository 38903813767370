.badge {
    display: inline-block;
    padding: .35em .65em;
    font-weight: 700;
    color: white;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .375rem;
    font-size: .75em;
    text-decoration: none !important;
}